<template lang="pug">
  #message
    h1 message page
</template>

<script>
export default {
  name: 'message',
  data () {
    return {

    }
  }
}

</script>

<style lang="sass">
</style>
